<h1 mat-dialog-title class="modal_head">
    Share your contact with {{profileName}}
    <span class="closebtn" (click)="closeModal()"><img src="assets/images/closeicn.svg"></span>
</h1>
<div mat-dialog-content class="m-0">
  <div class="content_sec">
      <form [formGroup]="createContactForm" (ngSubmit)="onSubmitForm()">
        <div class="input-wrap upload-contact" *ngIf="moreFields">
            <input type="file" class="cstm-input" placeholder="Email" accept="text/x-vcard" (change)="getVcfFile($event)">  
            <h6>Choose from Contacts
                <img src="assets/images/file-upload.svg">
            </h6>
        </div>
        <!-- <div><button (click)="chooseContact()">Add contact</button></div> -->
          <div class="input-wrap">
              <input type="email" class="cstm-input" placeholder="Email" formControlName="email"> 
              <ng-container *ngFor="let error of error_messages.email">
              <div *ngIf="createContactForm.get('email')?.hasError(error.type) && (createContactForm.get('email')?.dirty || createContactForm.get('email')?.touched)" >
                 {{error.message}}
              </div>
            </ng-container>
          </div>
          <div class="input-wrap">
            <input type="text" class="cstm-input" placeholder="First Name" formControlName="firstName">
            <div *ngIf="createContactForm.get('firstName')?.hasError(error_messages.firstName.type) && (createContactForm.get('firstName')?.dirty || createContactForm.get('firstName')?.touched)" >
                {{error_messages.firstName.message}}
             </div>
      
        </div>
        <div class="input-wrap">
            <input type="text" class="cstm-input" placeholder="Last Name" formControlName="lastName">  
        </div>
        <div class="input-wrap">
            <input type="tel" class="cstm-input" placeholder="Phone Number" formControlName="phoneNumber">
      
        </div>

        <button class="btn add-btn" *ngIf="!moreFields" (click)="addMore()">+ ADD MORE</button>

        <ng-container *ngIf="moreFields">

            <div class="input-wrap">
                <input  [matDatepicker]="picker" class="cstm-input" placeholder="Date of Birth" formControlName="dob">
                <mat-datepicker-toggle matIconSuffix [for]="picker">
                    <mat-icon matDatepickerToggleIcon><img src="assets/images/calendaricn.svg"></mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #picker panelClass="add-contct-date-picker"></mat-datepicker>
            </div>

            <div class="input-wrap">
                <input type="text" class="cstm-input" placeholder="Company/School" formControlName="company">  
            </div>

            <div class="input-wrap">
                <input type="text" class="cstm-input" placeholder="Title/Position" formControlName="title">  
            </div>

            <div class="input-wrap">
                <input type="text" class="cstm-input" placeholder="Website URL" formControlName="websiteUrl">  
            </div>

            <div class="input-wrap">
                <input type="text" class="cstm-input" placeholder="Address Street 1" formControlName="address">  
            </div>

            <div class="input-wrap">
                <input type="text" class="cstm-input" placeholder="Postal Code" formControlName="postalCode">  
            </div>

            <div class="input-wrap">
                <input type="text" class="cstm-input" placeholder="City" formControlName="city">  
            </div>

            <div class="input-wrap">
                <input type="text" class="cstm-input" placeholder="Country" formControlName="country">  
            </div>

            <div class="input-wrap">
                <textarea class="cstm-textarea" placeholder="Note/Bio" formControlName="note"></textarea>
            </div>


            <div class="photo-sec">
                <div class="img-wrap">
                    <img class="imb-fluid" [ngClass]="{'fit_img': imgURLCover != null}" [src]="imgURLCover? imgURLCover : 'assets/images/upload_img_contact.svg'">
                </div>
                <div class="input-wrap upload-contact photo">
                    <input type="file" class="cstm-input" placeholder="Email" (change)="getContactImages($event)">  
                    <h6>Upload a photo
                        <img src="assets/images/file-upload.svg">
                    </h6>
                </div>
            </div>          
        </ng-container>
        <button class="btn connect-btn" type="submit">Connect</button>
        <p class="bottom-p">B in Touch does not sell or share your data</p>
      </form>
  </div>
</div>
