import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../services/api-service/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CommonService } from '../../services/common/common.service';
import { environment } from '../../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ignoreElements } from 'rxjs/operators';



@Component({
  selector: 'app-nfc-tag',
  templateUrl: './nfc-tag.component.html',
  styleUrls: ['./nfc-tag.component.scss']
})
export class NfcTagComponent implements OnInit {


  isnoData = false;
  nfcData: any;
  private modalRef: any;
  qr_image = environment.qr_images;
  slug_images = environment.slug_images
  @ViewChild('mymodal', { static: false }) qrModal: ElementRef;
  qrimg: string;
  seqdata: any
  time: any;

  constructor(private modalService: NgbModal, private auth: ApiService, private router: Router, private route: ActivatedRoute, private common: CommonService) {
    this.common.showSpinner();
    this.route.params.subscribe((res: any) => {
      (!res)
        ? this.router.navigate(['/'])
        : this.getNfcTagInfo(res);
    })
  }

  ngOnInit(): void {
  }

   /* === NFC Tag (mobile view open to app) === */
  getNfcTagInfo(res: any) {
    let data = { 'tag_id': res.id };
    this.scanCount(res)
    this.auth.getNfcInfo(data).subscribe((response: any) => {
      if (response['success'] == 1) {
        this.nfcData = response
        if (response.item) {
          if (response.item.data.length == 0) {
            this.isnoData = true;
            this.common.error('No Data Found');
            this.common.hideSpinner();
            return
          } else {
            console.log(response.item.data + "," + response.item.slug)
            console.log(response.item.slug)
            if ((response.item.slug == 'lost_found') || (response.item.slug == 'emergency') || (response.item.slug == 'linktree') || (response.item.slug == 'emenu') || (response.item.slug == 'contact')) {
              console.log(response.item.data, response.item.slug)
              // console.log('iam in==================');
              const data: any = this.common.redirectToUrl(response.item.data, response.item.slug, '');
              if (data) {
                window.location.href = data.url;
              } else {
                this.common.error('Somthing went wrong');
                this.common.hideSpinner();
              }
            } else if (response.item.slug == 'event') {
              const encodedId = btoa(response.item.id);
              const data: any = this.common.redirectToUrl(encodedId, response.item.slug, '');
              // console.log(data,"===============");return
              if (data) {
                this.common.success('File downloaded successfully');
                window.location.href = data.url;
              } else {
                this.common.error('Somthing went wrong');
                this.common.hideSpinner();
              }
            }
            // else if(response.item.slug == 'email'){   // pending work.
            //   const encodedId = btoa(response.item.id);
            //   const data:any = this.common.redirectToUrl(encodedId,response.item.slug,'');
            //   // console.log(data,"===============");return
            //   }
            else if (response.item.slug == 'phone') {
              const data: any = this.common.redirectToUrl(this.nfcData.item.title, this.nfcData.item.slug, this.nfcData.item.data.content);
              // if (data) {
              //   window.location.href = data.url;
              // } else {
              //   this.common.error('Somthing went wrong');
              //   this.common.hideSpinner();
              // }

              // console.log(data,"===============");return
            }

            else if (response.item.slug == 'wifi') {
              this.common.hideSpinner();
              this.qrimg = this.qr_image + response.item.data.qrimage;
              this.modalRef = this.modalService.open(this.qrModal, { size: 'sm', ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
              return;
            }
            else if (response.item.slug == 'cv') {
              this.common.hideSpinner();
              const encodedId = btoa(response.item.id);
              let UrlData = this.nfcData.item.data.content ? this.nfcData.item.data.content : this.slug_images + this.nfcData.item.data.file_name
              console.log(UrlData)
              const data: any = this.common.redirectToUrl(UrlData, this.nfcData.item.slug, '');
              if (data) {
                window.location.href = data.url;
              } else {
                this.common.error('Somthing went wrong');
                this.common.hideSpinner();
              }
            }
            else if (response.item.slug == 'whatsApp') {
              this.common.hideSpinner();
              const encodedId = btoa(response.item.id);
              const data: any = this.common.redirectToUrl(this.nfcData.item.title, this.nfcData.item.slug, this.nfcData.item.data.content);
              if (data) {
                window.location.href = data.url;
              } else {
                this.common.error('Somthing went wrong');
                this.common.hideSpinner();
              }
            }
            else if (response.item.slug == 'notes') {
              this.common.hideSpinner();
              const data: any = this.common.redirectToUrl(this.nfcData.item.data, this.nfcData.item.slug, this.nfcData.item.data);
              if (data) {
                window.location.href = data.url;
              } else {
                this.common.error('Somthing went wrong');
                this.common.hideSpinner();
              }
            }
            else if (response.item.slug == 'sequencer') {
              let unix = moment().unix();
              let current =  moment().unix();
              let seq = response.item.data.services.split(',')
              this.time  = response.item.data.started_on == ""? unix:response.item.data.started_on
              if(response.item.data.started_on){
                let remaing = current -  this.time
                let hours:any = JSON.parse(response.item.data.reset_timer) * 60 * 60
                if(remaing < hours){
                  let nextItem
                  let index = seq.indexOf(response.item.data.current_service);
                  if (index >= 0 && index < seq.length - 1)
                    nextItem = seq[index + 1]
                  this.seqdata = nextItem
                  if(this.seqdata == undefined){
                    this.seqdata = seq[0]
                  }
                }else{
                 this.time = unix
                  this.seqdata = seq[0]
                  console.log(this.seqdata)
                }
              }else{
                this.seqdata = seq[0]
                  console.log(this.seqdata)
              }
              let seqsplit = this.seqdata.split('|')
              console.log(seqsplit)
              this.sequencer(seqsplit[0], seqsplit[1])
              this.updateSequecer(response, this.seqdata,  this.time)
            }
            else {
              let serviceData = { 'profile_user_id': response.item.data.user_id, 'slug': response.item.data.slug, 'service_id': response.item.data.id };
              this.auth.saveUserServicesClicks(serviceData).subscribe((response: any) => {
                if (response.success == 1) {
                  const data: any = this.common.redirectToUrl(this.nfcData.item.data.content, this.nfcData.item.slug, this.nfcData.item.data.title);
                  if (data) {
                    window.location.href = data.url;
                  } else {
                    this.common.error('Somthing went wrong');
                    this.common.hideSpinner();
                  }
                }
              })
            }
          }
        } else {
          this.router.navigate(['/profile', response.username])
        }
        this.common.hideSpinner();
      }else if (response['status'] == 'fresh'){
        let url = 'https://b-in-touch.fr/welcome-page'
         location.replace(url)
      } else {
        // let url = 'https://b-in-touch.fr/welcome-page'
        // location.replace(url)
        // let url = 'https://b-in-touch.fr/welcome-page'
        // location.replace(url)
        //this.common.error(response.msg);
        this.isnoData = true;
        // }
        this.common.hideSpinner();
      }
    }, (e) => {
      this.common.hideSpinner();
    })
  }


  scanCount(res: any) {   // calaculate the count of card scan
    let data = { 'tag_id': res.id };
    this.auth.totalScan(data).subscribe((response: any) => {

    })
  }

  deeplink(url: string, bundlename: string) {
    let ua = navigator.userAgent.toLowerCase();
    let isAndroid = ua.indexOf("android") > -1; // android check
    let isIphone = ua.indexOf("iphone") > -1; // ios check
    if (isIphone == false) {
      let app = {
        launchApp: function () {
          setTimeout(function () {
            window.open(url, '_blank')?.focus();
          }, 25);
          window.open(bundlename, '_blank')?.focus();

        },

        openWebApp: function () {
          window.open(url, '_blank')?.focus();
        }
      };
      app.launchApp();
    } else if (isAndroid == false) {
      let app = {
        launchApp: function () {
          window.open(bundlename, '_blank')?.focus();
          setTimeout(this.openWebApp, 500);
        },
        openWebApp: function () {
          window.open(url, '_blank')?.focus();
        }
      };
      app.launchApp();
    }
    else {
      window.open(url, '_blank')?.focus();
    }
  }

   /* === Functionality of Mobile view of sequncer  === */
  sequencer(id: any, slug: any) {
    const encodedId = btoa(id);
    let data = {
      id: encodedId,
      slug: slug,
      full:1
    }
    this.auth.getQrservices(data).subscribe((response: any) => {
      console.log(response)
      this.common.hideSpinner();
      if (response['success'] == 1) {
        if( response.item.slug == 'whatsApp'){
        const data: any = this.common.redirectToUrl(response.item.data.content, response.item.slug, response.item.title);
        if (data) {
          window.location.href = data.url;
        } else {
          this.common.error('Somthing went wrong');
          this.common.hideSpinner();
        }
      }   if ((response.item.slug == 'lost_found') || (response.item.slug == 'emergency') || (response.item.slug == 'linktree') || (response.item.slug == 'emenu') || (response.item.slug == 'contact')) {
        const data: any = this.common.redirectToUrl(response.item.data, response.item.slug, '');
        if (data) {
          window.location.href = data.url;
        } else {
          this.common.error('Somthing went wrong');
          this.common.hideSpinner();
        }
      } else if (response.item.slug == 'event') {
        const encodedId = btoa(response.item.id);
        const data: any = this.common.redirectToUrl(encodedId, response.item.slug, '');
        if (data) {
          this.common.success('File downloaded successfully');
          window.location.href = data.url;
        } else {
          this.common.error('Somthing went wrong');
          this.common.hideSpinner();
        }
      }
      else if (response.item.slug == 'phone') {
        const data: any = this.common.redirectToUrl(response.item.title, response.item.slug, response.item.data.content);
      }

      else if (response.item.slug == 'wifi') {
        this.common.hideSpinner();
        this.qrimg = this.qr_image + response.item.data.qrimage;
        this.modalRef = this.modalService.open(this.qrModal, { size: 'sm', ariaLabelledBy: 'modal-basic-title', backdrop: 'static' });
        return;
      }
      else if (response.item.slug == 'cv') {
        this.common.hideSpinner();
        const encodedId = btoa(response.item.id);
        let UrlData = response.item.data.content ? response.item.data.content : this.slug_images + response.item.data.file_name
        console.log(UrlData)
        const data: any = this.common.redirectToUrl(UrlData, response.item.slug, '');
        if (data) {
          window.location.href = data.url;
        } else {
          this.common.error('Somthing went wrong');
          this.common.hideSpinner();
        }
      }else {
        const data: any = this.common.redirectToUrl(response.item.data.content, response.item.slug, response.item.title);
        if (data) {
          window.location.href = data.url;
        } else {
          this.common.error('Somthing went wrong');
          this.common.hideSpinner();
        }
      }
    }
  })
  }

   /* === Update sequncer time and App  === */
  updateSequecer(res: any, current: any, time:any) {
    var data = new FormData
    data.append('id', res.item.data.id)
    data.append('reset_timer', res.item.data.reset_timer)
    data.append('title', res.item.data.title)
    data.append('services', res.item.data.services)
    data.append('current_service', current)
    data.append('started_on', time)
    data.append('user_id', res.item.data.user_id)
    this.auth.AppSequencer(data).subscribe((res: any) => {
      if (res.success == 1) {
      }
    })
  }

}
